<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank Guarantee Number<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.bankGuaranteeNumber"
                  :rules="[v => !!v || 'Bank Guarantee Number is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  return-object
                  item-text="purchaseOrderNumber"
                  v-model="purchaseOrder"
                  :items="salePurchaseOrders"
                  :rules="[v => !!v || 'PO is required']"
                  @change="onChangePurchaseOrder"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Type Transaction<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  :items="listTypeTransaction"
                  v-model="form.typeTransaction"
                  :rules="[v => !!v || 'Type Transaction is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Type Guarantee<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  :items="listTypeGuarantee"
                  v-model="form.typeGuarantee"
                  :rules="[v => !!v || 'Type Guarantee is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.typeGuarantee === 'BG'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bank<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="name"
                  item-value="id"
                  v-model="form.typeGuaranteeId"
                  :items="listBank"
                  :rules="[v => !!v || 'Bank is required']"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" v-if="form.typeGuarantee === 'TIBON'">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Insurance<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  item-text="name"
                  item-value="id"
                  v-model="form.typeGuaranteeId"
                  :items="listInsurance"
                  :rules="[v => !!v || 'Insurance is required']"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Start Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.startDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.startDate"
                    @input="menuStartDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>End Date<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.endDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.endDate"
                    @input="menuEndDate = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Margin<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  type="number"
                  v-model.number="form.margin"
                  :rules="[v => !!v || 'Margin is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPN<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  type="number"
                  v-model.number="form.ppn"
                  :rules="[v => !!v || 'PPN is required']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Document Upload<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-file-input
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="files"
                  multiple
                  :rules="[v => !!v || 'Document is required']"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  background-color="white"
                  v-model="form.remark"
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total PO</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalPo) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total PPN</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalPpn) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total MARGIN</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(totalMargin) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-btn color="primary" @click="submit">
              <v-icon large>
                mdi-content-save
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "create-bank-guarantee",
  data: () => ({
    defaultForm: {
      formNumber: "",
      bankGuaranteeNumber: "",
      poId: null,
      poNumber: "",
      customerName: "",
      typeGuarantee: "BG",
      remark: "",
      typeTransaction: "",
      typeGuaranteeId: null,
      startDate: moment().format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
      margin: 0,
      ppn: 11,
      totalPo: 0,
      totalPpn: 0,
      totalMargin: 0,
      statusList: [
        {
          status: "Outstanding",
        },
      ],
    },
    menuStartDate: false,
    menuEndDate: false,
    form: {},
    files: [],
    purchaseOrder: null,
    salePurchaseOrders: [],
    valid: true,
    listTypeGuarantee: ["BG", "TIBON"],
    listTypeTransaction: [
      "Jaminan Uang Muka",
      "Jaminan Pelaksanaan",
      "Perpanjangan Jaminan Uang Muka",
      "Perpanjangan Jaminan Pelaksanaan",
    ],
  }),

  computed: {
    totalPo() {
      return this.form.totalPo;
    },
    totalPpn() {
      return this.totalPo * (this.form.ppn / 100);
    },
    totalMargin() {
      let margin = this.totalPo * (this.form.margin / 100);
      let ppn = this.totalPpn * (this.form.margin / 100);
      return margin + ppn;
    },

    ...mapState("bankGuarantee", ["lastFormNumber"]),
    ...mapState("bank", ["listBank"]),
    ...mapState("insurance", ["listInsurance"]),
  },

  watch: {},

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        this.form.formNumber = this.lastFormNumber;
        this.form.totalPo = this.totalPo;
        this.form.totalPpn = this.totalPpn;
        this.form.totalMargin = this.totalMargin;

        const formData = new FormData();
        this.files.forEach(x => {
          formData.append("files", x);
        });
        formData.append(
          "bankGuaranteeDetail",
          new Blob([JSON.stringify(this.form)], {
            type: "application/json",
          })
        );

        await this.$store
          .dispatch("bankGuarantee/create", formData)
          .then(() => {
            this.$store.commit("SET_LOADER", false);
          })
          .catch(error => {
            if (error.response.status === 400) {
              this.$store.dispatch("toast", {
                type: "error",
                message: error.response.data,
              });
            } else {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
            }
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async getPurchaseOrder() {
      const response = await this.$store.dispatch("external/getSalePurchaseOrderAll", false);
      if (response.status === 200) {
        this.salePurchaseOrders = response.data;
      }
    },
    async onChangePurchaseOrder(val) {
      this.form.poId = val.id;
      this.form.poNumber = val.purchaseOrderNumber;
      this.form.customerName = val.account.name;
      this.form.totalPo = val.products
        .map(x => x.quantity * x.unitPrice)
        .reduce((a, b) => a + b, 0);
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        this.getPurchaseOrder(),
        await this.$store.dispatch("bank/dropdown"),
        await this.$store.dispatch("insurance/dropdown"),
        await this.$store.dispatch("bankGuarantee/getLastFormNumber"),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
};
</script>

<style></style>
